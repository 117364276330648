import React from "react"
import Layout from "../components/Layout"
import Image from "../components/Image"
import { docsAddress, repoAddress } from "../config/global"
import "./style.scss"

/* eslint-disable jsx-a11y/anchor-is-valid */
// Temporary disable rule for a href links

const Body = () => (
  <Layout>
    <section className="hero is-light">
      <div className="hero-body showcase-container">
        <div className="container has-text-centered">
          <Image
            alt="DataSonnet"
            filename="datasonnet/LogoOrange.png"
            className="image is-90x90 mb-20"
          />
          <span className="title is-size-1-desktop is-size-3-mobile has-text-weight-semibold main-title">
            DataSonnet
          </span>
          <span className="subtitle is-size-3-desktop is-size-5-mobile mw-500 main-subtitle">
            Your Open Source Data Transformation Solution
          </span>
          <div className="button-group">
            <div className="columns is-desktop">
              <div className="column">
                <a className="button is-primary" href={docsAddress}>Read the Docs</a>
              </div>
              <div className="column">
                <a className="button is-primary is-outlined" href={repoAddress}>Git Repo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="content-wrapper container is-desktop">
      <section>
        <div className="container center is-centered">
          <div className="columns is-centered m-b-60 reverse-order">
            <div className="column">
              <p className="title is-size-4-desktop is-size-6-mobile">
                DataSonnet provides a universal data transformation tool
              </p>
              <p className="is-size-6-desktop is-size-7-mobile content-text">
                Integrations are pivotal in today’s applications, and
                transforming data between apps is a requirement. Many
                integration platforms provide tools for mapping, but they can
                lock you into a particular vendor. DataSonnet was built to to be
                open, portable, and business friendly.
              </p>
            </div>
            <div className="column center">
              <div>
                <Image
                  alt="Integrations"
                  filename="datasonnet/WhyDataSonnet.png"
                  className="image is-200x200"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-desktop">
            <Reason title="Flexible and Open" image="datasonnet/Lock.png">
              DataSonnet was built to be portable. It works with ESBs,
              Java-based platforms, and as a standalone executable or
              microservice. And DataSonnet’s open source license eliminates
              vendor lock-in.
            </Reason>
            <Reason
              title="Tailored for Integration"
              image="datasonnet/Integration.png"
            >
            DataSonnet extends <a href="https://jsonnet.org/">JSonnet</a>, a data-templating language designed for generating configuration files, used by businesses like Google and DataBricks. DataSonnet adds a suite of powerful data integration functions for transforming data for EDI, APIs, microservices, and more.
            </Reason>
            <Reason title="Easy to Learn" image="datasonnet/RocketShip.png">
            DataSonnet has a simple javascript-like syntax. Strong typing prevents subtle errors, and the helpful error messages allow even occasional programmers to learn and build powerful transformations.
            </Reason>
          </div>
        </div>
      </section>
      <div className="container has-text-centered">
        <p className="title is-size-3-desktop is-size-5-mobile">
          Start Using DataSonnet Now!
        </p>
        <div className="button-group">
          <a className="button is-primary" href={repoAddress}>Git Repo</a>
        </div>
      </div>
    </div>
  </Layout>
)

const Reason = ({ title, image, children }) => (
  <div className="column has-text-centered m-b-50">
    <div className="tile">
      <article className="tile is-child has-background-white">
        <Image
          alt="Core reason"
          filename={image}
          className="image is-128x128 is-inline-block m-b-50"
        />

        <p className="title is-size-5-desktop is-size-6-mobile">{title}</p>
        <div className="content">
          <p className="is-size-6-desktop is-size-7-mobile reason-text">
            {children}
          </p>
        </div>
      </article>
    </div>
  </div>
)

export default () => <Body />